import { Column, Container } from '@123-front/ui-kit';
import { graphql, useStaticQuery } from 'gatsby';
import React, { useState } from 'react';
import CarLeadForm, { CarLeadData } from '../../../components/car-lead-form';
import FlexCenter from '../../../components/flex-center';
import Footer from '../../../components/footer';
import Hero from '../../../components/hero';
import Layout from '../../../components/layout';
import SEO from '../../../components/seo';
import SuccessNotice from '../../../components/success-notice';

const defaultLead: CarLeadData = {
  car: '',
  name: '',
  email: '',
  phone: '',
};

const FeaturedLandingBr: React.FC = () => {
  const [submitted, submit] = useState(false);
  const [leadInfo, setLeadInfo] = useState(defaultLead);
  const backgrounds = useStaticQuery(graphql`
    query {
      mobile: s3Object(localFile: { name: { eq: "photo_landing_bra_hero_mobile" } }) {
        localFile {
          childImageSharp {
            fluid(maxHeight: 280) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      desktop: s3Object(localFile: { name: { eq: "photo_landing_bra_hero_desktop" } }) {
        localFile {
          childImageSharp {
            fluid(maxHeight: 400) {
              ...GatsbyImageSharpFluid
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
    }
  `);

  return (
    <Layout gtmId="GTM-MDRH9XT">
      <SEO
        title="Seguro de Automóvel. Cotar Seguro de Carro"
        siteTitle="123Seguro Brasil"
        description="Compare preços de diferentes seguradoras e escolha o seguro de automóvel que melhor se adapta às suas necessidades. Aqui você pode cotar seguro de carro online!"
      />
      <Hero
        desktopBackground={backgrounds.desktop.localFile.childImageSharp.fluid}
        mobileBackground={backgrounds.mobile.localFile.childImageSharp.fluid}
        title="Cotar seguro automóvel"
        subtitle="Contrate o seguro de\ncarro perfeito para você"
      />
      <Container>
        <FlexCenter>
          <Column desktop="6">
            {submitted ? (
              <SuccessNotice
                noticeText={`Recebemos o seu pedido de cotação para o seu ${leadInfo.car}.Entraremos em contato pelo telefone (${leadInfo.phone}) ou por e-mail (${leadInfo.email}).`}
                solicitudText="Solicitação enviada!"
                verifyText="Não são seus dados?"
                onRequestChange={() => submit(false)}
                changeText="Modificarlos"
              />
            ) : (
              <CarLeadForm
                {...leadInfo}
                sampleCar="Ex.: Chevrolet Onix 2019"
                sampleName="Ex.: Pedro da Silva"
                sampleEmail="Ex.: nome@domínio.com"
                samplePhone="Ex.: 11956206000"
                labelCar="MARCA, MODELO E ANO DO SEU CARRO"
                labelName="NOME"
                labelEmail="E-MAIL"
                labelPhone="TELEFONE CELULAR"
                minPhoneLength={4}
                maxPhoneLength={12}
                countryCode="br"
                errorMessage="Por favor, verifique os campos com erro"
                missingCarErrorMessage="Insira um carro válido; ex.: Chevrolet Onix 2019"
                missingNameErrorMessage="Insira seu nome; ex.: Pedro da Silva"
                missingEmailErrorMessage="Insira seu email; ex.: nome@domínio.com"
                invalidEmailErrorMessage="Insira um email válido; ex.: nome@domínio.com"
                missingPhoneErrorMessage="Insira seu telefone; ex.: 11956206000"
                invalidPhoneErrorMessage="Insira um telefone válido; ex.: 11956206000"
                focusEmailMessage="E-mail para o qual enviaremos sua apólice"
                focusPhoneMessage="Telefone celular para gerenciar seu seguro"
                submitbutton="Solicitar cotação"
                onRegistration={(formValues) => {
                  setLeadInfo(formValues);
                  submit(true);
                }}
              ></CarLeadForm>
            )}
          </Column>
        </FlexCenter>
        <Footer text="" />
      </Container>
    </Layout>
  );
};

export default FeaturedLandingBr;
